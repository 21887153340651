import { useEffect } from 'react'
import { useUser } from 'src/hooks/useUser'

import { PageName, PageProperties } from '../pages'
import { PageTrackerOptions } from './types'
import { useCreateTracker } from './useCreateTracker'

export function usePageTracker<PN extends PageName>(
  pageName: PN,
  pageProperties: PageProperties<PN>,
  pageTrackerOptions?: PageTrackerOptions
) {
  const user = useUser()
  const tracker = useCreateTracker(
    pageName,
    pageProperties,
    pageTrackerOptions,
    user
  )

  useEffect(() => {
    tracker.page()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return tracker
}
