import pick from 'lodash/pick'

import { PageEvents, PageName, PageProperties } from './pages'

type PublishableEvent<PN extends PageName, EN extends keyof PageEvents<PN>> = {
  pageProperties: (keyof PageProperties<PN>)[]
  eventProperties: (keyof PageEvents<PN>[EN])[]
}

const publishableEvents: {
  [PN in PageName]?: {
    [EN in keyof PageEvents<PN>]?: PublishableEvent<PN, EN>
  }
} = {
  Viewer: {
    'Flow Rendered': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
    'Hotspot Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'hotspotId', 'hotspotLabel'],
    },
    'CTA Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'ctaLabel'],
    },
    'Video Ended': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId'],
    },
    'Flow Restarted': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
  },
}

export function publishToHost<
  PN extends PageName,
  EN extends keyof PageEvents<PN>
>(
  pageName: PN,
  eventName: EN,
  pageProperties: PageProperties<PN>,
  eventProperties: PageEvents<PN>[EN]
) {
  const pageEvents = publishableEvents[pageName]

  if (!pageEvents || !(eventName in pageEvents)) return

  const eventDefinition = pageEvents[eventName] as PublishableEvent<PN, EN>
  if (typeof eventDefinition === 'undefined') return

  const event = {
    eventName,
    eventTimestamp: new Date().toISOString(),
    ...pick(pageProperties, eventDefinition.pageProperties),
    ...pick(eventProperties, eventDefinition.eventProperties),
  }

  window.parent?.postMessage(event, '*')
}
