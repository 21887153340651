import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import colors from 'styles/colors'

type Colors = {
  active: {
    primary: string
    secondary: string
  }
  inactive: string
}

type Props = {
  className?: string
  square?: boolean
  typeColor?: string
  iconColors?: Colors
  onClick?: () => void
  hideTitleWhenSmall?: boolean
  animate?: boolean
}

const Logo = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      square = true,
      typeColor = colors.gray[900],
      iconColors = {
        inactive: colors.gray[900],
        active: {
          primary: colors.yellow[500],
          secondary: colors.yellow[100],
        },
      },
      onClick,
      hideTitleWhenSmall = true,
      animate = true,
    },
    ref
  ) => {
    const [isHover, setIsHover] = useState(false)
    const [isActive, setIsActive] = useState(false)

    return (
      <div
        ref={ref}
        className={classNames('inline-block', {
          'cursor-pointer': !!(animate || onClick),
        })}
        onMouseEnter={
          animate
            ? () => {
                setIsActive(false)
                setIsHover(true)
              }
            : undefined
        }
        onMouseLeave={
          animate
            ? () => {
                setIsActive(false)
                setIsHover(false)
              }
            : undefined
        }
        onMouseDown={animate ? () => setIsActive(true) : undefined}
        onMouseUp={animate ? () => setIsActive(false) : undefined}
        onClick={onClick}
      >
        <svg
          className={className}
          viewBox={`0 0 ${square ? 142 : 583} 100`}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          {!square && (
            <g
              className={classNames({
                'hidden sm:block': hideTitleWhenSmall,
              })}
            >
              <path
                d='M217.252 72.8739H193.49L189.878 83.8551H170.297L194.725 16.5353H216.207L240.54 83.8551H220.864L217.252 72.8739ZM212.595 58.5505L205.371 36.779L198.242 58.5505H212.595Z'
                fill={typeColor}
              />
              <path
                d='M280.545 83.8551L267.238 59.0279H265.242V83.8551H246.612V16.5353H275.983C281.369 16.5353 285.931 17.4902 289.67 19.4C293.409 21.2461 296.229 23.8243 298.13 27.1346C300.031 30.3812 300.981 34.0417 300.981 38.1159C300.981 42.6993 299.714 46.7417 297.179 50.243C294.708 53.6806 291.064 56.1314 286.248 57.5956L301.361 83.8551H280.545ZM265.242 46.2324H274.462C276.997 46.2324 278.898 45.6277 280.165 44.4181C281.432 43.2086 282.066 41.458 282.066 39.1662C282.066 37.0018 281.401 35.3148 280.07 34.1053C278.803 32.8321 276.933 32.1955 274.462 32.1955H265.242V46.2324Z'
                fill={typeColor}
              />
              <path
                d='M307.172 50.052C307.172 43.4314 308.534 37.5429 311.259 32.3865C314.047 27.1664 317.976 23.1241 323.046 20.2594C328.115 17.3311 333.945 15.8669 340.535 15.8669C348.899 15.8669 355.933 18.1268 361.636 22.6466C367.339 27.1028 371.015 33.1823 372.662 40.885H352.606C351.402 38.3387 349.723 36.3971 347.569 35.0602C345.478 33.7234 343.038 33.0549 340.25 33.0549C335.941 33.0549 332.487 34.6146 329.889 37.7339C327.354 40.7895 326.087 44.8956 326.087 50.052C326.087 55.272 327.354 59.4417 329.889 62.561C332.487 65.6167 335.941 67.1445 340.25 67.1445C343.038 67.1445 345.478 66.4761 347.569 65.1392C349.723 63.8024 351.402 61.8608 352.606 59.3144H372.662C371.015 67.0172 367.339 73.1285 361.636 77.6483C355.933 82.1045 348.899 84.3325 340.535 84.3325C333.945 84.3325 328.115 82.9002 323.046 80.0355C317.976 77.1072 314.047 73.0648 311.259 67.9084C308.534 62.6884 307.172 56.7362 307.172 50.052Z'
                fill={typeColor}
              />
              <path
                d='M423.877 72.8739H400.114L396.502 83.8551H376.922L401.35 16.5353H422.831L447.164 83.8551H427.489L423.877 72.8739ZM419.219 58.5505L411.995 36.779L404.867 58.5505H419.219Z'
                fill={typeColor}
              />
              <path
                d='M479.755 16.5353C486.789 16.5353 492.936 17.9677 498.195 20.8323C503.518 23.6334 507.605 27.5802 510.457 32.673C513.308 37.7657 514.734 43.5906 514.734 50.1475C514.734 56.6407 513.277 62.4337 510.362 67.5265C507.51 72.6192 503.423 76.6298 498.1 79.5581C492.841 82.4228 486.726 83.8551 479.755 83.8551H453.236V16.5353H479.755ZM478.33 67.3355C483.779 67.3355 488.056 65.8395 491.161 62.8475C494.266 59.8555 495.819 55.6222 495.819 50.1475C495.819 44.6091 494.266 40.3439 491.161 37.3519C488.056 34.2963 483.779 32.7685 478.33 32.7685H471.866V67.3355H478.33Z'
                fill={typeColor}
              />
              <path
                d='M541.762 31.5271V42.5083H563.148V56.7362H541.762V68.8633H566V83.8551H523.132V16.5353H566V31.5271H541.762Z'
                fill={typeColor}
              />
            </g>
          )}
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M138.254 45.2848C138.296 44.7906 138.317 44.2936 138.317 43.7939C138.317 25.7384 110.935 11.1014 77.1584 11.1014C43.3815 11.1014 16 25.7384 16 43.7939C16 44.2936 16.021 44.7906 16.0625 45.2848C16.021 45.779 16 46.276 16 46.7757C16 47.2753 16.021 47.7724 16.0625 48.2665C16.021 48.7607 16 49.2577 16 49.7574C16 50.257 16.021 50.7541 16.0625 51.2483C16.021 51.7424 16 52.2395 16 52.7391C16 53.2388 16.021 53.7358 16.0625 54.23C16.021 54.7242 16 55.2212 16 55.7209C16 56.2205 16.021 56.7175 16.0625 57.2117C16.021 57.7059 16 58.2029 16 58.7026C16 59.2022 16.021 59.6993 16.0625 60.1934C16.021 60.6876 16 61.1847 16 61.6843C16 62.184 16.021 62.681 16.0625 63.1752C16.021 63.6694 16 64.1664 16 64.666C16 82.7216 43.3815 97.3586 77.1584 97.3586C110.935 97.3586 138.317 82.7216 138.317 64.666C138.317 64.1664 138.296 63.6694 138.254 63.1752C138.296 62.681 138.317 62.184 138.317 61.6843C138.317 61.1847 138.296 60.6876 138.254 60.1934C138.296 59.6993 138.317 59.2022 138.317 58.7026C138.317 58.2029 138.296 57.7059 138.254 57.2117C138.296 56.7175 138.317 56.2205 138.317 55.7209C138.317 55.2212 138.296 54.7242 138.254 54.23C138.296 53.7358 138.317 53.2388 138.317 52.7391C138.317 52.2395 138.296 51.7424 138.254 51.2483C138.296 50.7541 138.317 50.257 138.317 49.7574C138.317 49.2577 138.296 48.7607 138.254 48.2665C138.296 47.7724 138.317 47.2753 138.317 46.7757C138.317 46.276 138.296 45.779 138.254 45.2848Z'
            fill={isHover ? iconColors.active.primary : iconColors.inactive}
          />
          <path
            d='M137.317 43.8251C137.317 52.3141 130.87 60.2301 119.932 66.0825C109.036 71.9124 93.9154 75.5488 77.1584 75.5488C60.4014 75.5488 45.2803 71.9124 34.3847 66.0825C23.4468 60.2301 17 52.3141 17 43.8251C17 35.3361 23.4468 27.4202 34.3847 21.5677C45.2803 15.7378 60.4014 12.1014 77.1584 12.1014C93.9154 12.1014 109.036 15.7378 119.932 21.5677C130.87 27.4202 137.317 35.3361 137.317 43.8251Z'
            fill={isHover ? iconColors.active.secondary : '#fff'}
            stroke={isHover ? iconColors.active.primary : iconColors.inactive}
            strokeWidth='2'
          />

          <g
            className={classNames('', {
              'opacity-0': isActive,
              'transition-opacity opacity-100': !isActive,
            })}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M122.701 28.4959C122.732 28.1273 122.748 27.7566 122.748 27.3839C122.748 13.9171 102.336 3 77.1571 3C51.978 3 31.5663 13.9171 31.5663 27.3839C31.5663 27.7566 31.582 28.1273 31.6129 28.4959C31.582 28.8645 31.5663 29.2352 31.5663 29.6079C31.5663 29.9805 31.582 30.3513 31.6129 30.7198C31.582 31.0884 31.5663 31.4591 31.5663 31.8318C31.5663 32.2045 31.582 32.5752 31.6129 32.9438C31.582 33.3124 31.5663 33.6831 31.5663 34.0558C31.5663 34.4284 31.582 34.7991 31.6129 35.1677C31.582 35.5363 31.5663 35.907 31.5663 36.2797C31.5663 36.6524 31.582 37.0231 31.6129 37.3917C31.582 37.7603 31.5663 38.131 31.5663 38.5036C31.5663 38.8763 31.582 39.247 31.6129 39.6156C31.582 39.9842 31.5663 40.3549 31.5663 40.7276C31.5663 41.1002 31.582 41.471 31.6129 41.8395C31.582 42.2081 31.5663 42.5789 31.5663 42.9515C31.5663 56.4184 51.978 67.3355 77.1571 67.3355C102.336 67.3355 122.748 56.4184 122.748 42.9515C122.748 42.5789 122.732 42.2081 122.701 41.8395C122.732 41.471 122.748 41.1002 122.748 40.7276C122.748 40.3549 122.732 39.9842 122.701 39.6156C122.732 39.247 122.748 38.8763 122.748 38.5036C122.748 38.131 122.732 37.7603 122.701 37.3917C122.732 37.0231 122.748 36.6524 122.748 36.2797C122.748 35.907 122.732 35.5363 122.701 35.1677C122.732 34.7991 122.748 34.4284 122.748 34.0558C122.748 33.6831 122.732 33.3124 122.701 32.9438C122.732 32.5752 122.748 32.2045 122.748 31.8318C122.748 31.4591 122.732 31.0884 122.701 30.7198C122.732 30.3513 122.748 29.9805 122.748 29.6079C122.748 29.2352 122.732 28.8645 122.701 28.4959Z'
              fill={isHover ? iconColors.active.primary : iconColors.inactive}
            />
            <path
              d='M121.748 27.3839C121.748 33.5697 117.044 39.4008 108.923 43.7442C100.845 48.0649 89.6154 50.7678 77.1572 50.7678C64.6989 50.7678 53.4697 48.0649 45.3912 43.7442C37.2704 39.4008 32.5663 33.5697 32.5663 27.3839C32.5663 21.1981 37.2704 15.3671 45.3912 11.0237C53.4697 6.70294 64.6989 4 77.1572 4C89.6154 4 100.845 6.70294 108.923 11.0237C117.044 15.3671 121.748 21.1981 121.748 27.3839Z'
              fill={isHover ? iconColors.active.secondary : '#fff'}
              stroke={isHover ? iconColors.active.primary : iconColors.inactive}
              strokeWidth='2'
            />
          </g>

          <path
            className={classNames('transition-opacity', {
              'opacity-0': !isActive,
              'opacity-100': isActive,
            })}
            d='M121.248 43.3839C121.248 49.2959 116.743 54.9945 108.687 59.3033C100.695 63.5781 89.5497 66.2678 77.1571 66.2678C64.7646 66.2678 53.6196 63.5781 45.627 59.3033C37.5709 54.9945 33.0663 49.2959 33.0663 43.3839C33.0663 37.4719 37.5709 31.7733 45.627 27.4646C53.6196 23.1898 64.7646 20.5 77.1571 20.5C89.5497 20.5 100.695 23.1898 108.687 27.4646C116.743 31.7733 121.248 37.4719 121.248 43.3839Z'
            fill={isHover ? iconColors.active.secondary : '#fff'}
            stroke={isHover ? iconColors.active.primary : iconColors.inactive}
            strokeWidth='4'
          />
        </svg>
      </div>
    )
  }
)
Logo.displayName = 'Logo'
export default Logo
