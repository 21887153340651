import { useEffect, useMemo } from 'react'
import { User } from 'src/types'

import EventTracker from '../EventTracker'
import { PageName, PageProperties } from '../pages'
import { PageTrackerOptions } from './types'

export function useCreateTracker<PN extends PageName>(
  pageName: PN,
  pageProperties: PageProperties<PN>,
  pageTrackerOptions?: PageTrackerOptions,
  user?: User
) {
  const tracker = useMemo(
    () => new EventTracker<PN>(pageName, pageProperties, pageTrackerOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (user) {
      tracker.setUser(user)
    }
  }, [tracker, user])

  useEffect(() => {
    tracker.setPageProperties(pageProperties)
  }, [tracker, pageProperties])

  return tracker
}
